import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

export const Root = styled(Box, {
  name: 'FormMarketoEmbed',
  slot: 'Root',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => styles.root,
})<{ variant?: string }>(() => ({}));

export const FormCircularProgress = styled(CircularProgress, {
  name: 'FormCircularProgress',
  slot: 'FormCircularProgress',
})({
  zIndex: 1,
  position: 'absolute',
  top: '25%',
  left: 'calc(50% - 20px)',
  transform: 'translateX(-50%)',
  color: 'primary.contrastText',
});

export const FormWrapper = styled(Box, {
  name: 'FormMarketoEmbed',
  slot: 'FormWrapper',
  shouldForwardProp: (prop) => prop !== 'variant' && prop !== 'hidden',
  overridesResolver: (_, styles) => styles.root,
})<{ variant?: string; hidden?: boolean }>(({ hidden }) => ({
  position: 'relative',
  width: '100%',
  maxWidth: 1300,
  margin: '0 auto',
  display: hidden ? 'none !important' : 'block',
}));

export const Form = styled('form', {
  name: 'FormMarketoEmbed',
  slot: 'Form',
  shouldForwardProp: (prop) => prop !== 'variant',
  overridesResolver: (_, styles) => styles.form,
})<{ variant?: string }>(({ theme }) => {
  const placeholder = {
    color: theme.palette.primary.contrastText,
    opacity: 0.5,
    transition: theme.transitions.create('opacity', {
      duration: theme.transitions.duration.shorter,
    }),
  };

  return {
    width: '100% !important',
    minHeight: 602,
    marginLeft: theme.spacing(-2),
    [theme.breakpoints.down('lg')]: {
      marginLeft: 0,
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
      '& [class*="mktoFormRow"]': {
        flexDirection: 'column',
        marginBottom: '0 !important',
        "& [class*='mktoOffset']": {
          display: 'none !important',
        },
      },
    },
    '& div': {
      display: 'flex',
      '& .mktoFormCol': {
        flex: 1,
      },
    },
    '& .mktoLabel': {
      display: 'none',
    },
    '& .mktoErrorMsg': {
      backgroundImage: 'none !important',
    },
    '& .mktoFormRow': {
      width: '100%',
    },
    '& .mktoOffset': {
      display: 'block !important',
    },
    '& .mktoFieldWrap': {
      width: '100%',
      color: theme.palette.primary.contrastText,
      lineHeight: '1.4375em', // 23px
      boxSizing: 'border-box', // Prevent padding issue with fullWidth.
      position: 'relative',
      cursor: 'text',
      display: 'inline-flex',
      alignItems: 'center',
      borderRadius: 4,
      borderColor: theme.palette.background.default,
      borderWidth: 1,
      borderStyle: 'solid',
      backgroundColor: theme.palette.primary.main,
      '& input': {
        width: '100% !important',
        font: 'inherit',
        letterSpacing: 'inherit',
        color: 'inherit',
        padding: '16.5px 14px !important',
        border: 0,
        boxSizing: 'content-box',
        background: 'none',
        height: '1.4375em', // Reset 23px the native input line-height
        margin: 0, // Reset for Safari
        WebkitTapHighlightColor: 'transparent',
        display: 'block',
        // Make the flex item shrink with Firefox
        minWidth: 0,
        animationName: 'mui-auto-fill-cancel',
        animationDuration: '10ms',
        '&::-webkit-input-placeholder': placeholder,
        '&::-moz-placeholder': placeholder, // Firefox 19+
        '&:-ms-input-placeholder': placeholder, // IE11
        '&::-ms-input-placeholder': placeholder, // Edge
        '&:focus': {
          outline: 0,
        },
        // Reset Firefox invalid required input style
        '&:invalid': {
          boxShadow: 'none',
        },
        '&::-webkit-search-decoration': {
          // Remove the padding when type=search.
          WebkitAppearance: 'none',
        },
      },
      '&[class*="-select"]': {
        '& select.mktoField': {
          height: '41px !important',
          boxShadow: 'none',
          color: `${theme.palette.secondary.contrastText} !important`,
          fontSize: '1rem !important',
        },

        '&:after': {
          zIndex: 0,
          top: 51,
          right: 12,
          borderLeftWidth: 5,
          borderRightWidth: 5,
          borderTopWidth: 7,
          borderTopColor: theme.palette.secondary.contrastText,
        },
      },
    },
    '& .mktoButtonWrap': {
      width: '100%',
      marginLeft: '0 !important',
      [theme.breakpoints.up('lg')]: {
        marginLeft: '10px !important',
      },
      '& button': {
        width: '100%',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        boxSizing: 'border-box',
        WebkitTapHighlightColor: 'transparent',
        backgroundImage: 'none !important',
        // We disable the focus ring for mouse, touch and keyboard users.
        outline: 0,
        border: `0 !important`,
        backgroundColor: `${theme.palette.grey.A100} !important`,
        boxShadow: theme.shadows[2],
        // Reset on touch devices, it doesn't add specificity
        '@media (hover: none)': {
          boxShadow: theme.shadows[2],
          backgroundColor: `${theme.palette.grey[300]} !important`,
        },
        '&: hover': {
          backgroundColor: 'rgb(178, 178, 178) !important',
        },
        margin: 0, // Remove the margin in Safari
        borderRadius: 4,
        padding: '8px 22px !important',
        cursor: 'pointer',
        userSelect: 'none',
        verticalAlign: 'middle',
        MozAppearance: 'none', // Reset
        WebkitAppearance: 'none', // Reset
        color: 'inherit !important',
        '&::-moz-focus-inner': {
          borderStyle: 'none', // Remove Firefox dotted outline.
        },
        '@media print': {
          colorAdjust: 'exact',
        },
      },
    },
  };
});
