import React, { useEffect } from 'react';
import Script from 'next/script';
import Text from '../../Text';

import { sidekick } from '../../../utils/sidekick';
import { isEmailGood } from '../shared/isEmailGood';
import { BaseLeadFormProps } from '../shared/types';
import { addFieldModifiers } from '../shared/addFieldModifiers';
import { Form, FormCircularProgress, FormWrapper, Root } from '../shared/BaseComponents';
import { styled } from '@mui/material/styles';

export interface FormInfogramEmbedProps extends BaseLeadFormProps {
  infogramId: string;
}

function logFormSubmitEvent(formId: string) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'marketo-formSubmission',
    formName: formId,
    formSuccess: true,
  });
}

const FormInfogramEmbed = ({
  baseUrl,
  munchkinId,
  formId,
  introText,
  infogramId,
  sidekickLookup,
  variant,
}: FormInfogramEmbedProps) => {
  const infogramRef = React.useRef<HTMLDivElement>(null);
  const [loaded, setLoaded] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [success, setSuccess] = React.useState(false);

  // Load Infogram script
  useEffect(() => {
    const scriptElement = document.createElement('script');
    scriptElement.src = 'https://e.infogram.com/js/dist/embed-loader-min.js';
    scriptElement.async = true;
    scriptElement.id = 'infogram-async';

    document.body.appendChild(scriptElement);

    return () => {
      document.body.removeChild(scriptElement);
    };
  }, []);

  // Load and initialize Marketo form
  useEffect(() => {
    if (window.MktoForms2) {
      window.MktoForms2.loadForm(baseUrl, munchkinId, formId, addFieldModifiers);
      window.MktoForms2.whenReady((form: any) => {
        setIsLoading(false);
        form.onValidate(() => {
          const email = form.vals().Email;
          if (!isEmailGood(email)) {
            form.submitable(false);
            const emailElem = form.getFormElem().find('#Email');
            form.showErrorMessage('Must be Business email.', emailElem);
          } else {
            form.submitable(true);
          }
        });

        form.onSuccess(() => {
          setSuccess(true);
          logFormSubmitEvent(form.getId());

          // Show the Infogram embed
          const pageElement = document.getElementById('page-content');
          if (window['InfogramEmbeds']?.initialized && pageElement) {
            window['InfogramEmbeds']?.process();
            infogramRef.current?.classList.add('visible');
            pageElement.innerHTML = infogramRef.current?.outerHTML || '';
          }

          return false;
        });
      });
    }
  }, [loaded]);

  return (
    <Root {...sidekick(sidekickLookup)} data-testid="FormInfogramEmbed" variant={variant}>
      <Script
        strategy="afterInteractive"
        id="marketo"
        src={`${baseUrl}/js/forms2/js/forms2.min.js`}
        onError={(e) => {
          console.error('Script failed to load', e);
        }}
        onLoad={() => {
          setLoaded(true);
        }}
      />
      <FormWrapper hidden={success} data-testid="FormInfogramEmbed-formWrapper" className={variant}>
        {introText && <IntroText {...introText} />}
        {isLoading && <FormCircularProgress size={40} data-testid="FormInfogramEmbed-circularProgress" />}
        <Form id={`mktoForm_${formId}`} data-testid="FormInfogramEmbed-form" />
      </FormWrapper>

      <InfogramEmbed className="infogram-embed" data-id={infogramId} ref={infogramRef} />
    </Root>
  );
};

export default FormInfogramEmbed;

const IntroText = styled(Text, {
  name: 'FormMarketoEmbed',
  slot: 'IntroText',
})(() => ({
  '& h2:first-of-type[class*="MuiTypography-root"]': {
    paddingBottom: 0,
  },
  '& h4:first-of-type[class*="MuiTypography-root"]': {
    paddingBottom: 24,
  },
}));

const InfogramEmbed = styled('div')`
  display: none;
  &.visible {
    display: flex;
  }
`;
